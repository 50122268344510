<template>
  <div
    class="cards-add container van-tab__panel"
    :style="{ backgroundImage: 'url(img/pro-bg.png)' }"
  >
    <van-form @submit="onSubmit">
      <van-cell-group inset class="con">
        <van-field
          v-model="form.account_no"
          id="edit"
          @focus="inputHandle"
          :disabled="type === 'detail'"
          :label="$t('bank.text4')"
          :placeholder="$t('public.input') + $t('bank.text4')"
          class="blank-input"
        />
        <!-- <van-field v-model="form.re_account_no" v-if="type !== 'detail'" :label="$t('bank.text5')"
          :placeholder="$t('public.input') + $t('bank.text5')" /> -->
        <!--
        <van-field v-model="form.branch" :disabled="type === 'detail'" :label="$t('bank.text6')"
          :placeholder="$t('public.input') + $t('bank.text6')" />
          -->
        <van-field
          v-model="form.bank"
          :label="$t('bank.text7')"
          :placeholder="$t('public.input') + $t('bank.text7')"
          :disabled="type === 'detail'"
          class="mt-10"
        />
        <!-- <van-field
          v-model="bankName"
          :label="$t('bank.text7')"
          :placeholder="$t('public.input') + $t('bank.text7')"
          class="mt-10 uploader"
          readonly
          :disabled="type === 'detail'"
          :is-link="type !== 'detail'"
          @click="
            () => {
              if (type !== 'detail') {
                showPicker = true;
              }
            }
          "
        />
         <van-field
          v-model="cityName"
          :label="$t('bank.text8')"
          :placeholder="$t('public.input') + $t('bank.text8')"
          class="mt-10 uploader"
          readonly
          :disabled="type === 'detail'"
          :is-link="type !== 'detail'"
          @click="
            () => {
              if (type !== 'detail') {
                showCity = true;
              }
            }
          "
        /> -->
        <van-field
          v-model="form.address"
          :label="$t('bank.text8')"
          :placeholder="$t('public.input') + $t('bank.text8')"
          :disabled="type === 'detail'"
          class="mt-10"
        />
        <van-field
          v-model="form.account_name"
          :label="$t('bank.text9')"
          :placeholder="$t('public.input') + $t('bank.text9')"
          :disabled="type === 'detail'"
          class="mt-10"
        />
        <van-field
          v-model="form.security_password"
          type="password"
          :label="$t('bank.text10')"
          v-if="type !== 'detail'"
          :placeholder="$t('public.input') + $t('bank.text10')"
          class="mt-10"
        />
      </van-cell-group>
      <div style="margin-top: 16px" v-if="type !== 'detail'">
        <van-button round block type="primary" native-type="submit">
          {{ $t("public.submit") }}
        </van-button>
      </div>
    </van-form>
    <van-popup v-model:show="showPicker" position="bottom">
      <van-picker
        :columns="columns"
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
    <van-popup v-model:show="showCity" position="bottom">
      <!-- <van-area title="选择地区" :area-list="areaList" :columns-placeholder="['请选择', '请选择', '请选择']" /> -->
      <!-- <van-picker :columns="columnsCity" @confirm="onConfirmCity" @cancel="showCity = false" /> -->
      <van-cascader
        v-model="cascaderValue"
        :label="$t('bank.text11')"
        :options="columnsCity"
        @close="showCity = false"
        :field-names="fieldNames"
        @change="onChange"
        @finish="onFinish"
      />
    </van-popup>
  </div>
</template>

<script setup>
import { reactive, ref, toRaw } from "vue";
import { Toast, Dialog } from "vant";
import { getBanksName, addCard, getBanks } from "@/api/user";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
import store from "@/store";
const router = useRouter();
const { userInfo } = store.getters;
const { type, id } = router.currentRoute.value.query;
const showPicker = ref(false);
const showCity = ref(false);

const columns = ref([]);
const columnsCity = ref([]);
const bankName = ref("");
const cityName = ref("");
const cascaderValue = ref("");

const form = reactive({
  bank_id: "",
  region: [],
  branch: "",
  address: "",
  account_name: "",
  account_no: "",
  re_account_no: "",
  security_password: "",
});
let bankList = [];
Toast.loading({
  duration: 0,
  forbidClick: true,
});
const fieldNames = {
  text: "name",
  value: "id",
  children: "cities",
};

const getProvinceCity = (parent_id, callback) => {
  const parmas = {
    flag: parent_id == 0 ? 0 : "get_city",
    parent_id: parent_id,
  };
  getBanksName(parmas).then((res) => {
    Toast.clear();
    if (res.status === 0) {
      callback(res.data);
    } else {
      Dialog.alert({
        message: res.msg,
      }).then(() => {
        if (res.msg.indexOf("密码") !== -1) {
          router.replace("/pages/passwordChange?type=safe&isOff=true");
        } else {
          router.go(-1);
        }
      });
    }
  });
};

if (type === "detail" && id) {
  getBanks({ id: id }).then((res) => {
    const {
      account_name,
      account_name_all,
      account_all,
      branch,
      bank_name,
      account,
      province,
      city,
      district,
      address,
      bank,
    } = res.data.user_banks;
    bankName.value = res.data.user_banks.bankName;
    cityName.value = province + city + district;
    bankName.value = bank_name;
    form.account_name =
      userInfo.info.id == 21321 ? account_name_all : account_name;
    form.branch = branch;
    form.address = address;
    form.bank = bank;
    form.account_no = userInfo.info.id == 21321 ? account_all : account;
    console.log(res.data);
    Toast.clear();
  });
} else {
  getProvinceCity(0, (data) => {
    bankList = data.banks;
    bankList.forEach((item) => {
      columns.value.push(item.name + "(" + item.ident + ")");
    });
    columnsCity.value = data.province;
    console.log(columnsCity.value);
    Toast.clear();
  });
}

// 禁止复制回调
const nocopy = () => {
  let edit = document.getElementById("edit");
  edit.oncopy = function () {
    Toast("禁止复制");
    return false;
  };
};
// 禁止粘贴回调
const nopaste = () => {
  let edit = document.getElementById("edit");
  edit.onpaste = function () {
    Toast("禁止粘贴");
    return false;
  };
};
const inputHandle = () => {
  nocopy();
  nopaste();
};
const onChange = ({ value, selectedOptions, tabIndex }) => {
  const findIndex = columnsCity.value.findIndex(
    (item) => item.id === selectedOptions[0].id
  );
  console.log(value, selectedOptions[0]);
  getProvinceCity(value, (data) => {
    if (tabIndex === 0) {
      columnsCity.value[findIndex].cities = data;
    } else if (tabIndex === 1) {
      const findChild = columnsCity.value[findIndex].cities.findIndex(
        (item) => item.id === value
      );
      columnsCity.value[findIndex].cities[findChild].cities = data;
    }
    console.log(value, data);
  });
};
const onFinish = ({ selectedOptions }) => {
  showCity.value = false;
  form.region = [];
  const names = [];
  selectedOptions.forEach((option) => {
    form.region.push(option.id);
    names.push(option.name);
  });
  cityName.value = names.join("/");
  console.log("onFinish--", selectedOptions);
};
const onConfirm = (value, index) => {
  form.bank_id = bankList[index].id;
  bankName.value = value;
  showPicker.value = false;
};
const onSubmit = () => {
  const toastLoading = Toast.loading({
    duration: 0,
    forbidClick: true,
  });
  if (form.address == "") {
    Toast.fail(t("public.fill"));
    return;
  }
  addCard({ bankinfo: toRaw(form) })
    .then((res) => {
      Toast.success(res.msg);
      setTimeout(() => {
        router.push("/my");
      }, 1500);
    })
    .catch(() => {
      toastLoading.clear();
    });
};
</script>

<style lang="less" scoped>
.cards-add {
  background-repeat: no-repeat;
  background-size: 80% auto;
  background-position: 0 -50px;
  color: #ddd;

  .card {
    font-size: 50px;
  }
  .blank-input {
    input {
      user-select: none;
    }
  }
  .van-cell-group--inset {
    margin: 10px 0;
  }
}
</style>
